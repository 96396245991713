import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import { CheckCircle, Info } from 'lucide-react'
import api from 'config/axiosConfig'
import Modal from 'ui/Modal'
import { UsernameValidationResponse } from 'util/constants'

interface Props {
    onClose: () => void
}

const UsernameModal: React.FC<Props> = ({ onClose }) => {
    const { currentUser, setCurrentUser } = useUser()
    const [username, setUsername] = useState<string>('')
    const [isValid, setIsValid] = useState<boolean>(false)
    const [validationMessage, setValidationMessage] = useState<string>('')
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const validateUsername = async (username: string): Promise<void> => {
        if (
            !/^[a-z0-9_.]{2,30}$/.test(username) ||
            /\.{2,}/.test(username) ||
            /^admin/.test(username) ||
            /admin$/.test(username) ||
            username === 'user' ||
            username.includes('enemiesofearth')
        ) {
            setIsValid(false)
            setValidationMessage('Invalid or restricted username format.')
            return
        }

        try {
            const response = await api.get<UsernameValidationResponse>(`/api/user/${username}`)
            if (response.data.isValid) {
                setIsValid(true)
                setValidationMessage('Username is available.')
            } else {
                setIsValid(false)
                setValidationMessage(response.data.message)
            }
        } catch (error) {
            // If API call fails, assume username might be available
            setIsValid(true)
            setValidationMessage('Username is available.')
        }
    }

    useEffect(() => {
        if (username) {
            validateUsername(username)
        } else {
            setIsValid(false)
            setValidationMessage('')
        }
    }, [username])

    const handleSubmit = async (): Promise<void> => {
        if (!isValid || isSubmitting) return
        if (!currentUser) return
        setIsSubmitting(true)
        try {
            await api.patch('/api/user/username', { username })
            const userResponse = await api.get('/api/user')
            setCurrentUser(userResponse.data)
            onClose()
        } catch (error) {
            console.error('Error updating username:', error)
            setValidationMessage('Failed to update username. Please try again.')
        } finally {
            setIsSubmitting(false)
        }
    }

    const handleClose = (): void => {
        onClose()
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const lowercaseUsername = e.target.value.toLowerCase()
        setUsername(lowercaseUsername)
    }

    return (
        <Modal isOpen={true} onClose={handleClose} title="Set your Username">
            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className="space-y-4">
                    <p className="text-eoe-text dark:text-eoe-dark-text">
                        Usernames are publicly visible.
                    </p>
                    <div className="form-control">
                        <label htmlFor="username-input" className="form-label">Username</label>
                        <div className="relative">
                            <input
                                id="username-input"
                                type="text"
                                className="input-text w-full"
                                value={username}
                                placeholder={currentUser?.username || 'Enter new username'}
                                onChange={handleInputChange}
                                aria-invalid={!isValid && username.length > 0}
                            />
                            {username && (
                                <div className="absolute inset-y-0 right-0 pr-1 flex items-center pointer-events-none">
                                    {isValid ? (
                                        <CheckCircle className="text-green-600 size-4" />
                                    ) : (
                                        <Info className="text-red-600 size-4" />
                                    )}
                                </div>
                            )}
                        </div>
                        {validationMessage && (
                            <p className={`form-helper-text ${isValid ? 'text-green-600' : 'text-red-600'}`}>
                                {validationMessage}
                            </p>
                        )}
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            className="btn-secondary"
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={`btn-primary ${!isValid || isSubmitting ? 'btn-primary-disabled' : ''}`}
                            disabled={!isValid || isSubmitting}
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

export default UsernameModal
