// WelcomeModal.tsx

import React from 'react';
import Modal from 'ui/Modal'; // Adjust the import path as necessary

interface Props {
  open: boolean;
  onClose: () => void;
}

const WelcomeModal: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Modal isOpen={open} onClose={onClose} title="Welcome!">
      <div className="prose dark:prose-invert space-y-4">
        {/* Content */}
        <p>
          We believe that people should determine enemies—not politicians.
        </p>
        <p>
          Enemies of Earth (EOE) is a platform, powered by{' '}
          <a
            href="https://societea.io"
            target="_blank"
            rel="noopener noreferrer"
            className="external-link"
          >
            Societea
          </a>
          , for identifying, discussing, and prioritizing threats, their impacts, and potential
          solutions.
        </p>
        <div className="space-y-2">
          <h3 className="font-semibold text-eoe-text-heading dark:text-eoe-dark-text-heading">
            How it works:
          </h3>
          <ul className="list-disc pl-5 space-y-1">
            <li>
              Browse and search for threats ("<a href="/enemies" className="external-link">Enemies</a>")
            </li>
            <li>
              View, discuss, and prioritize Enemies and their{' '}
              <a href="/impacts" className="external-link">Impacts</a> and proposed{' '}
              <a href="/solutions" className="external-link">Solutions</a>
            </li>
            <li>
              Add new Impacts and Solutions for existing Enemies, or identify new Enemies
            </li>
          </ul>
        </div>
        <p>
          Please stay{' '}
          <a
            href="https://societea.io/policies/content-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="external-link"
          >
            passionate but respectful
          </a>
          , and feel free to share{' '}
          <a href="/feedback" className="external-link">feedback</a>.
        </p>
        <p>
          <i>United we stand, prioritized they fall.</i>
        </p>
        {/* Actions */}
        <div className="flex justify-end mt-4">
          <button className="btn-primary" onClick={onClose}>
            Got it
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
