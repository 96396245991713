import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import { useNavigate } from 'react-router-dom'
import { ChevronRight } from 'lucide-react'
import UsernameModal from 'components/User/UsernameModal'
import UserAvatarModal from 'components/User/UserAvatarModal'

const UserSettingsProfile: React.FC = () => {
    const { currentUser, awaitingUser } = useUser()
    const navigate = useNavigate()
    const [showUsernameModal, setShowUsernameModal] = useState<boolean>(false)
    const [showAvatarModal, setShowAvatarModal] = useState<boolean>(false)

    useEffect(() => {
        if (!awaitingUser && !currentUser) {
            navigate('/login', { replace: true })
        }
    }, [currentUser, awaitingUser, navigate])

    return (
        <div className="p-4">
            <div className="form-control space-y-2">
                <label className="form-label text-lg">General</label>
                {/* Username Section */}
                <div
                    className="group flex justify-between items-center p-4 bg-eoe-icon dark:bg-eoe-dark-bg-hover rounded-md cursor-pointer hover:bg-eoe-icon-hover dark:hover:bg-eoe-dark-icon-hover"
                    onClick={() => setShowUsernameModal(true)}
                    role="button"
                    aria-haspopup="dialog"
                    aria-controls="username-modal"
                >
                    <div className="flex flex-col items-start">
                        <span className="text-eoe-text dark:text-eoe-dark-text">Username</span>
                        <p className="text-xs text-eoe-text dark:text-eoe-dark-text-sub">Change your username.</p>
                    </div>
                    <ChevronRight className="inline-arrow-icon" />
                </div>
                {/* Avatar Section */}
                <div
                    className="group flex justify-between items-center p-4 bg-eoe-icon dark:bg-eoe-dark-bg-hover rounded-md cursor-pointer hover:bg-eoe-icon-hover dark:hover:bg-eoe-dark-icon-hover"
                    onClick={() => setShowAvatarModal(true)}
                    role="button"
                    aria-haspopup="dialog"
                    aria-controls="avatar-modal"
                >
                    <div className="flex flex-col items-start">
                        <span className="text-eoe-text dark:text-eoe-dark-text">Avatar</span>
                        <p className="text-xs text-eoe-text dark:text-eoe-dark-text-sub">Add or change your profile avatar.</p>
                    </div>
                    <ChevronRight className="inline-arrow-icon" />
                </div>
            </div>

            {/* Username Modal */}
            {showUsernameModal && (
                
                <UsernameModal onClose={() => setShowUsernameModal(false)} />
            )}

            {/* Avatar Modal */}
            {showAvatarModal && (
                
                <UserAvatarModal onClose={() => setShowAvatarModal(false)} />
            )}
        </div>
    )

}

export default UserSettingsProfile