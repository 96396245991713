import React, { useState, useEffect, useRef } from 'react';
import { useUser } from 'util/UserContext';
import api from 'config/axiosConfig';
import { Info } from 'lucide-react'; // Using lucide-react icons
import Modal from 'ui/Modal'; // Adjust the import path as necessary
import { Enemy } from 'util/constants';

interface Props {
    open: boolean;
    onClose: (value: boolean) => void;
    prepopulateData?: { name: string; wikipediaLink: string } | null;
}

interface WikiSearchResult {
    title: string;
    link: string;
}

const AddEnemy: React.FC<Props> = ({ open, onClose, prepopulateData }) => {
    const { currentUser, setShowAuthModal } = useUser();
    const [wikipediaLink, setWikipediaLink] = useState<string>(prepopulateData?.wikipediaLink || '');
    const [name, setName] = useState<string>(prepopulateData?.name || '');
    const [error, setError] = useState<string>('');
    const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
    const [existingEnemyLink, setExistingEnemyLink] = useState<string>('');
    const [searchResults, setSearchResults] = useState<WikiSearchResult[]>([]);
    const [isChecking, setIsChecking] = useState<boolean>(false);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const validateWikipediaLink = (link: string) => {
        const regex = /^https:\/\/en\.wikipedia\.org\/wiki\/[a-zA-Z0-9_().'%,-]+$/;
        if (regex.test(link)) {
            const namePart = decodeURIComponent(link.split('/wiki/')[1]).replace(/_/g, ' ');
            setName(namePart);
            setError('');
            setIsChecking(true);
            setSubmitEnabled(true);
            checkEnemyExistence(namePart);
        } else {
            setError('Invalid Wikipedia link. Example: https://en.wikipedia.org/wiki/Crime');
            setSubmitEnabled(false);
            setIsChecking(false);
        }
    };

    const handleWikipediaLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setWikipediaLink(value);
        validateWikipediaLink(value);
    };

    const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setName(value);
        setWikipediaLink('');
        setError('');
        setSubmitEnabled(false);
        setExistingEnemyLink('');
        if (value.length > 2) {
            try {
                const response = await fetch(
                    `https://en.wikipedia.org/w/api.php?action=opensearch&search=${encodeURIComponent(
                        value
                    )}&limit=5&namespace=0&format=json&origin=*`
                );
                const data = await response.json();
                const results: WikiSearchResult[] = data[1].map((title: string, index: number) => ({
                    title,
                    link: data[3][index],
                }));
                setSearchResults(results);
            } catch (error) {
                console.error('Error fetching search results:', error);
            }
        } else {
            setSearchResults([]);
        }
    };

    const handleSearchSelect = async (result: WikiSearchResult) => {
        setName(result.title);
        try {
            const response = await fetch(
                `https://en.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(result.title)}`
            );
            const data = await response.json();
            const wikipediaLink = data.content_urls.desktop.page;
            setWikipediaLink(wikipediaLink);
            validateWikipediaLink(wikipediaLink);
        } catch (error) {
            console.error('Error fetching page summary:', error);
        }
        setSearchResults([]);
    };

    const checkEnemyExistence = async (enemyName: string) => {
        setIsChecking(true);
        setSubmitEnabled(false);
        try {
            const response = await api.get(`/api/enemies?search=${encodeURIComponent(enemyName)}`);
            if (response.data.length > 0) {
                const existingEnemy = response.data.find(
                    (enemy: Enemy) => enemy.name.toLowerCase() === enemyName.toLowerCase()
                );
                if (existingEnemy) {
                    const enemyUrl = `/enemy/${encodeURIComponent(existingEnemy.name.replace(/ /g, '_'))}`;
                    setExistingEnemyLink(enemyUrl);
                    setError(`The enemy already exists: `);
                    setSubmitEnabled(false);
                } else {
                    setExistingEnemyLink('');
                    setSubmitEnabled(true);
                }
            } else {
                setExistingEnemyLink('');
                setSubmitEnabled(true);
            }
        } catch (err) {
            console.error('Failed to check if enemy exists:', err);
            setError('Failed to check if enemy exists. Please try again.');
            setSubmitEnabled(false);
        } finally {
            setIsChecking(false);
        }
    };

    useEffect(() => {
        const checkIfEnemyExists = async () => {
            if (prepopulateData) {
                validateWikipediaLink(prepopulateData.wikipediaLink);
            } 
            if (name) {
                await checkEnemyExistence(name);
            }
        };

        checkIfEnemyExists();
    }, [name, prepopulateData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!currentUser) {
            setShowAuthModal(true);
            return;
        }

        try {
            const standardizedLink = wikipediaLink.replace(
                /^https?:\/\/(www\.)?wikipedia\.org\/wiki\//,
                'https://en.wikipedia.org/wiki/'
            );
            const response = await api.post('/api/enemies', {
                wikipedia_link: standardizedLink,
                user_id: currentUser.id,
            });
            if (response.status === 201) {
                window.location.href = `/enemy/${encodeURIComponent(name.replace(/ /g, '_'))}?upvoted=true`;
            }
        } catch (error) {
            console.error('Failed to create enemy:', error);
            setError('Failed to create enemy. Please try again later.');
        }
    };

    const handleCloseModal = () => {
        onClose(false);
        // Optionally reload or reset state here if needed
    };

    return (
        <Modal isOpen={open} onClose={handleCloseModal} title="Add an Enemy">
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-4 mb-4">
                    {/* Name Input */}
                    <div className="form-control">
                        <label htmlFor="enemy-name" className="form-label">
                            Name
                        </label>
                        <div className="relative">
                            <input
                                id="enemy-name"
                                type="text"
                                value={name}
                                onChange={handleSearchChange}
                                className="input-text block w-full px-3 py-2"
                                placeholder="Search for an enemy..."
                                ref={searchInputRef}
                                autoComplete="off"
                            />
                            {searchResults.length > 0 && (
                                <ul className="absolute z-10 mt-1 w-full bg-eoe-bg dark:bg-eoe-dark-bg border border-eoe-border-main dark:border-eoe-dark-border-main rounded-md shadow-lg max-h-48 overflow-auto">
                                    {searchResults.map((result, index) => (
                                        <li
                                            key={index}
                                            className="px-3 py-2 hover:bg-eoe-bg-hover dark:hover:bg-eoe-dark-bg-hover cursor-pointer text-eoe-text dark:text-eoe-dark-text"
                                            onClick={() => handleSearchSelect(result)}
                                        >
                                            {result.title}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    {/* Wikipedia Link Input */}
                    <div className="form-control">
                        <label htmlFor="wikipedia-link" className="form-label">
                            Wikipedia Link
                        </label>
                        <input
                            id="wikipedia-link"
                            type="text"
                            value={wikipediaLink}
                            onChange={handleWikipediaLinkChange}
                            placeholder="https://en.wikipedia.org/wiki/Crime"
                            className="input-text block w-full px-3 py-2"
                        />
                    </div>
                </div>
                {/* Error Message */}
                {error && (
                    <div className="text-red-500 text-sm mb-4 flex items-center">
                        <Info className="mr-1" />
                        {existingEnemyLink ? (
                            <>
                                {error}
                                <a
                                    href={existingEnemyLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="internal-link underline hover:no-underline"
                                >
                                    {name}
                                </a>
                            </>
                        ) : (
                            <span>{error}</span>
                        )}
                    </div>
                )}
                {/* Buttons */}
                <div className="flex gap-2 justify-end mt-2">
                    <button
                        type="button"
                        onClick={handleCloseModal}
                        className="btn-secondary"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={!submitEnabled || isChecking}
                        className={`btn-primary ${
                            !submitEnabled || isChecking ? 'btn-primary-disabled' : ''
                        }`}
                    >
                        {isChecking ? 'Checking...' : 'Submit'}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default AddEnemy;