import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUser } from 'util/UserContext'
import api from 'config/axiosConfig'
import AddImpact from './AddImpact'
import ImpactCard from './ImpactCard'
import AddButton from 'ui/AddButton'
import { ChevronDown, Loader } from 'lucide-react'
import { Select, Option } from '@mui/joy'

import {
    Enemy,
    Impact,
    Scope,
    Sort,
    SortField,
    SortOrder,
} from 'util/constants'

interface Props {
    sort: Sort
    enemy: Enemy
}

const ImpactsSection = ({ enemy }: Props) => {
    const { currentUser, setShowAuthModal } = useUser()
    const location = useLocation()
    const navigate = useNavigate()
    const [impacts, setImpacts] = useState<Impact[]>([])
    const [error, setError] = useState<string>('')
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [scopeFilter, setScopeFilter] = useState<Scope | 'All'>('All')
    const [impactTypeFilter, setImpactTypeFilter] = useState<
        'All' | 'impact_negative' | 'impact_positive'
    >('All')
    const [sortField, setSortField] = useState<SortField>('created_ts')
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc')
    const searchParams = new URLSearchParams(location.search)
    const sort = (searchParams.get('sort') as Sort) || 'new'

    useEffect(() => {
        switch (sort) {
            case 'top':
                setSortField('vote_count')
                setSortOrder('desc')
                break
            case 'bottom':
                setSortField('vote_count')
                setSortOrder('asc')
                break
            case 'new':
                setSortField('created_ts')
                setSortOrder('desc')
                break
            case 'old':
                setSortField('created_ts')
                setSortOrder('asc')
                break
            default:
                setSortField('created_ts')
                setSortOrder('desc')
                break
        }
    }, [sort])

    useEffect(() => {
        const fetchImpacts = async () => {
            try {
                const response = await api.get<Impact[]>(
                    `/api/impacts/enemies/${enemy.id}`
                )
                setImpacts(response.data)
            } catch (err) {
                setError('Failed to load impacts. Please try again later.')
            }
        }

        if (enemy) {
            fetchImpacts()
        }
    }, [enemy])

    const handleImpactAdded = async () => {
        try {
            const response = await api.get<Impact[]>(
                `/api/impacts/enemies/${enemy.id}`
            )
            setImpacts(response.data)
        } catch (err) {
            setError('Failed to refresh impacts. Please reload the page.')
        }
    }

    const handleSortChange = (newSort: Sort | 'updated') => {
        const currentParams = new URLSearchParams(location.search)
        
        switch (newSort) {
            case 'updated':
                // Toggle between asc/desc for updated_ts
                setSortField('updated_ts')
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                break
            case 'top':
                setSortField('vote_count')
                setSortOrder('desc')
                currentParams.set('sort', 'top')
                break
            case 'bottom':
                setSortField('vote_count')
                setSortOrder('asc')
                currentParams.set('sort', 'bottom')
                break
            case 'new':
                setSortField('created_ts')
                setSortOrder('desc')
                currentParams.set('sort', 'new')
                break
            case 'old':
                setSortField('created_ts')
                setSortOrder('asc')
                currentParams.set('sort', 'old')
                break
        }

        if (newSort !== 'updated') {
            navigate(`?${currentParams.toString()}`)
        }
    }

    const handleAddImpactButtonClick = () => {
        if (!currentUser) {
            setShowAuthModal(true)
        } else {
            setOpenModal(true)
        }
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const filteredImpacts = impacts.filter((impact) => {
        const matchesScope =
            scopeFilter === 'All' || impact.scope === scopeFilter
        const matchesImpactType =
            impactTypeFilter === 'All' || impact.rel_type === impactTypeFilter
        return matchesScope && matchesImpactType
    })

    const sortedImpacts = [...filteredImpacts].sort((a, b) => {
        if (!sortField) return 0

        let aValue, bValue

        switch (sortField) {
            case 'vote_count':
                aValue = a.vote_count || 0
                bValue = b.vote_count || 0
                break
            case 'created_ts':
                aValue = new Date(a.created_ts || 0).getTime()
                bValue = new Date(b.created_ts || 0).getTime()
                break
            case 'updated_ts':
                aValue = new Date(a.updated_ts || 0).getTime()
                bValue = new Date(b.updated_ts || 0).getTime()
                break
            default:
                return 0
        }

        if (sortOrder === 'asc') {
            return aValue - bValue
        } else {
            return bValue - aValue
        }
    })

    const SortSelector = () => (
        <Select
            value={sort}
            onChange={(_, newValue) => handleSortChange(newValue as Sort)}
            className="entity-filter-select"
            indicator={<ChevronDown className="entity-filter-select-indicator size-4" />}
        >
            <Option value="top" className="entity-filter-select-options">Top</Option>
            <Option value="new" className="entity-filter-select-options">New</Option>
            <Option value="bottom" className="entity-filter-select-options">Bottom</Option>
            <Option value="old" className="entity-filter-select-options">Old</Option>
        </Select>
    )

    if (error) {
        return <div className="error-message">{error}</div>
    }

    if (!enemy) {
        return (
            <div className="flex justify-center items-center my-4">
                <Loader className="animate-spin h-12 w-12 text-eoe-gray dark:text-white" />
            </div>
        )
    }

    return (
        <div>
            {openModal && (
                <AddImpact
                    enemyId={enemy.id}
                    onImpactAdded={handleImpactAdded}
                    open={openModal}
                    onClose={handleCloseModal}
                />
            )}
            <div className="flex flex-col space-y-1 mb-1">
                <div className="flex mb-1">
                    <AddButton
                        tooltipTitle="Add Impact"
                        buttonText="Impact"
                        className="mx-2 w-full sm:w-auto"
                        onClick={handleAddImpactButtonClick}
                    />
                </div>
                <div className="flex mx-2 gap-1">
                    <SortSelector />
                    <Select
                        value={scopeFilter || ''}
                        onChange={(_, newValue) => setScopeFilter(newValue as Scope)}
                        className="entity-filter-select"
                        indicator={<ChevronDown className="entity-filter-select-indicator size-4" />}
                    >
                        <Option value="All" className="entity-filter-select-options">Scope</Option>
                        <Option value="Local" className="entity-filter-select-options">Local</Option>
                        <Option value="National" className="entity-filter-select-options">National</Option>
                        <Option value="Global" className="entity-filter-select-options">Global</Option>
                    </Select>
                    <Select
                        value={impactTypeFilter}
                        onChange={(_, newValue) =>
                            setImpactTypeFilter(newValue as 'All' | 'impact_negative' | 'impact_positive')
                        }
                        className="entity-filter-select"
                        indicator={<ChevronDown className="entity-filter-select-indicator size-4" />}
                    >
                        <Option value="All" className="entity-filter-select-options">Type</Option>
                        <Option value="impact_negative" className="entity-filter-select-options">Negative</Option>
                        <Option value="impact_positive" className="entity-filter-select-options">Positive</Option>
                    </Select>
                </div>
            </div>
            {impacts.length === 0 ? (
                <p className="mt-2">No impacts exist for this enemy.</p>
            ) : (
                <div className="flex flex-col divide-y divide-eoe-border-main dark:divide-eoe-dark-border-main">
                    {sortedImpacts.map((impact) => (
                        <ImpactCard
                            key={impact.id}
                            impact={impact}
                            truncateDescription={true}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default ImpactsSection