import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import { Link, useSearchParams } from 'react-router-dom'
import api from 'config/axiosConfig'
import EnemyCard from 'components/Enemies/EnemyCard'
import ImpactCard from 'components/Impacts/ImpactCard'
import SolutionCard from 'components/Solutions/SolutionCard'
import FeedbackCard from 'components/Feedback/FeedbackCard'
import EntitiesSort from 'components/Entities/EntitiesSort'
import { SavedEntity, Sort } from 'util/constants'
import { Loader } from 'lucide-react'

const UserProfileSaved: React.FC = () => {
    const { currentUser } = useUser()
    const [searchParams, setSearchParams] = useSearchParams()
    const [savedEntities, setSavedEntities] = useState<SavedEntity[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const [offset, setOffset] = useState(0)
    const [hasMore, setHasMore] = useState(true)
    const limit = 10

    const sort = (searchParams.get('sort') as Sort) || 'new'

    const fetchUserSavedEntities = async (reset = false) => {
        try {
            const response = await api.get(`/api/saves`, {
                params: {
                    sortField: getSortField(sort),
                    sortOrder: getSortOrder(sort),
                    offset: reset ? 0 : offset,
                    limit
                }
            })
            if (reset) {
                setSavedEntities(response.data)
                setOffset(limit)
            } else {
                setSavedEntities(prev => [...prev, ...response.data])
                setOffset(prev => prev + limit)
            }
            setHasMore(response.data.length === limit)
            setLoading(false)
        } catch (err) {
            setError('Failed to load saved entities')
            setLoading(false)
        }
    }

    useEffect(() => {
        if (currentUser?.username) {
            fetchUserSavedEntities(true)
        } else {
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser?.username, sort])

    const getSortField = (sortValue: Sort): 'saved_ts' | 'vote_count' => {
        switch (sortValue) {
            case 'top':
            case 'bottom':
                return 'vote_count'
            case 'new':
            case 'old':
                return 'saved_ts'
            default:
                return 'saved_ts'
        }
    }

    const getSortOrder = (sortValue: Sort): 'DESC' | 'ASC' => {
        switch (sortValue) {
            case 'top':
            case 'new':
                return 'DESC'
            case 'bottom':
            case 'old':
                return 'ASC'
            default:
                return 'DESC'
        }
    }

    const handleSortChange = (newSort: Sort) => {
        setSearchParams({ sort: newSort })
        setOffset(0)
        setSavedEntities([])
    }

    const loadMore = () => {
        fetchUserSavedEntities()
    }

    const renderEntityCard = (entity: SavedEntity) => {
        switch (entity.entity_type) {
            case 'enemy':
                return (
                    <EnemyCard
                        key={entity.eid}
                        enemy={entity.attributes}
                        showMeta={true}
                        showActions={true}
                        truncateDescription={true}
                        isEnemyProfilePage={false}
                    />
                )
            case 'impact':
                return (
                    <ImpactCard
                        key={entity.eid}
                        impact={entity.attributes}
                        showMeta={true}
                        truncateDescription={true}
                        isImpactProfilePage={false}
                    />
                )
            case 'solution':
                return (
                    <SolutionCard
                        key={entity.eid}
                        solution={entity.attributes}
                        showMeta={true}
                        truncateDescription={true}
                        isSolutionProfilePage={false}
                    />
                )
            case 'feedback':
                return (
                    <FeedbackCard
                        key={entity.eid}
                        feedback={entity.attributes}
                        showMeta={true}
                        truncateDescription={true}
                        isFeedbackProfilePage={false}
                    />
                )
            default:
                return null
        }
    }

    if (!currentUser?.username) {
        return (
            <div className="flex flex-col items-center gap-2 py-4">
                <h4 className="text-eoe-red dark:text-eoe-dark-text">
                    You do not have permission to access this page.
                </h4>
                <Link to={`/user/${currentUser?.username}/saved`} className="btn-primary">
                    View Your Saved Items
                </Link>
            </div>
        )
    }

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Loader className="animate-spin h-12 w-12 text-eoe-gray dark:text-white" />
            </div>
        )
    }

    if (error) {
        return <p className="error-message">{error}</p>
    }

    return (
        <>
            <div className="flex mx-2 gap-1 items-center">
                <EntitiesSort entityType="saved" sort={sort} onSortChange={handleSortChange} />
            </div>
            <div className="flex flex-col entity-divider">
                {savedEntities.map((entity) => renderEntityCard(entity))}
            </div>
            {hasMore && (
                <button
                    onClick={loadMore}
                    className={`mt-2 ${loading ? 'btn-primary-disabled' : 'btn-primary'}`}
                    disabled={loading}
                >
                    {loading ? 'Loading...' : 'Load More'}
                </button>
            )}
        </>
    )
}

export default UserProfileSaved