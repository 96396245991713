import React, { useState } from 'react';
import { useUser } from 'util/UserContext';
import { Feedback } from 'util/constants';
import { Info } from 'lucide-react'; // Using lucide-react icons
import api from 'config/axiosConfig';
import TextEditor from 'components/TextEditor';
import Modal from 'ui/Modal'; // Adjust the import path as necessary

interface Props {
    open: boolean;
    onClose: () => void;
    onFeedbackAdded: (feedback: Feedback) => void;
}

const AddFeedback: React.FC<Props> = ({ open, onClose, onFeedbackAdded }) => {
    const { currentUser, setShowAuthModal } = useUser();
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [category, setCategory] = useState<Feedback['properties']['category']>('Bug');
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleAddFeedback = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!currentUser) {
            setShowAuthModal(true);
            return;
        }

        if (!title.trim() || !description.trim()) {
            setError('Title and description are required.');
            setSuccess('');
            return;
        }

        setIsSubmitting(true);
        setError('');
        setSuccess('');

        try {
            const feedbackData = {
                title: title.trim(),
                description: description.trim(),
                category,
                user_id: currentUser.id,
            };

            const response = await api.post<Feedback>('/api/feedback', feedbackData);

            const newFeedback = response.data;

            setSuccess('Feedback added successfully!');
            onFeedbackAdded(newFeedback);
            setTitle('');
            setDescription('');
            setCategory('Bug');
            onClose();
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                setShowAuthModal(true);
            } else {
                setError('Failed to add feedback. Please try again.');
                setSuccess('');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCloseModal = () => {
        onClose();
        setTitle('');
        setDescription('');
        setCategory('Bug');
        setError('');
        setSuccess('');
    };

    return (
        <Modal isOpen={open} onClose={handleCloseModal} title="Add Feedback">
            <form onSubmit={handleAddFeedback} className="flex flex-col space-y-4">
                {/* Title Field */}
                <div className="form-control">
                    <label htmlFor="feedback-title" className="form-label">
                        Title
                    </label>
                    <input
                        type="text"
                        id="feedback-title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="input-text block w-full px-3 py-2"
                        placeholder="Enter feedback title"
                        required
                    />
                </div>

                {/* Category Field */}
                <div className="form-control">
                    <label htmlFor="feedback-category" className="form-label">
                        Category
                    </label>
                    <select
                        id="feedback-category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value as Feedback['properties']['category'])}
                        className="input-select block w-full px-3 py-2"
                    >
                        <option value="Bug">Bug</option>
                        <option value="Enhancement">Enhancement</option>
                        <option value="Functionality">Functionality</option>
                        <option value="User Interface (UI)">User Interface (UI)</option>
                        <option value="User Experience (UX)">User Experience (UX)</option>
                        <option value="Performance">Performance</option>
                        <option value="Compliance">Compliance</option>
                        <option value="Other">Other</option>
                    </select>
                </div>

                {/* Description Field */}
                <div className="form-control">
                    <label htmlFor="feedback-description" className="form-label">
                        Description
                    </label>
                    <TextEditor
                        value={description}
                        onChange={setDescription}
                        editorType="entity"
                    />
                </div>

                {/* Error and Success Messages */}
                {(error || success) && (
                    <div className="flex items-center space-x-2">
                        {error && (
                            <div className="flex items-center text-red-500 text-sm">
                                <Info className="mr-1" />
                                <span>{error}</span>
                            </div>
                        )}
                        {success && (
                            <div className="flex items-center text-green-500 text-sm">
                                <Info className="mr-1" />
                                <span>{success}</span>
                            </div>
                        )}
                    </div>
                )}

                {/* Action Buttons */}
                <div className="flex justify-end space-x-2">
                    <button
                        type="button"
                        onClick={handleCloseModal}
                        className="btn-secondary"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn-primary ${isSubmitting ? 'btn-primary-disabled' : ''}`}
                    >
                        {isSubmitting ? 'Submitting...' : 'Add Feedback'}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default AddFeedback;