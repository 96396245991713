import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import { useNavigate } from 'react-router-dom'
import { ChevronRight } from 'lucide-react'
import api from 'config/axiosConfig'
import Modal from 'ui/Modal'

const UserSettingsAccount: React.FC = () => {
    const { currentUser, awaitingUser } = useUser()
    const navigate = useNavigate()
    const [openDeactivateModal, setOpenDeactivateModal] = useState<boolean>(false)
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [reason, setReason] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false)

    useEffect(() => {
        if (!awaitingUser && !currentUser) {
            navigate('/login', { replace: true })
        }
    }, [currentUser, awaitingUser, navigate])

    const handleEmailConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
        const emailValue = e.target.value
        setEmail(emailValue)
        setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue))
        setErrorMessage('')
    }

    const handleDeactivate = async () => {
        if (!currentUser) return
        if (!isEmailValid || email !== currentUser.email) {
            setErrorMessage(
                'The provided email is not associated with this account.'
            )
            return
        }
        try {
            await api.post('/api/user/deactivate', {
                username: currentUser.username,
                reason,
            })
            window.location.href = '/logout'
        } catch (error) {
            console.error('Error deactivating account:', error)
            setErrorMessage('Failed to deactivate account. Please try again later.')
        }
    }

    const handleDelete = async () => {
        if (!currentUser) return
        if (!isEmailValid || email !== currentUser.email) {
            setErrorMessage(
                'The provided email is not associated with this account.'
            )
            return
        }
        setOpenDeleteModal(false)
        if (
            window.confirm(
                'Are you sure? Your profile and username will be permanently removed.'
            )
        ) {
            try {
                await api.delete('/api/user/delete', {
                    data: { username: currentUser.username, reason },
                })
                window.location.href = '/logout'
            } catch (error) {
                console.error('Error deleting account:', error)
                setErrorMessage('Failed to delete account. Please try again later.')
            }
        }
    }

    return (
        <div className="p-4">
            <div className="space-y-4">
                <div className="form-control space-y-2">
                    <label className="form-label text-lg">Advanced</label>
                    <div
                        className="group flex justify-between items-center p-4 bg-eoe-bg-hover dark:bg-eoe-dark-bg-hover rounded-md cursor-pointer hover:bg-eoe-icon-hover dark:hover:bg-eoe-dark-icon-hover"
                        onClick={() => setOpenDeactivateModal(true)}
                    >
                        <div className="flex flex-col items-start">
                            <span className="text-eoe-text dark:text-eoe-dark-text">Deactivate Account</span>
                            <p className="text-xs text-eoe-text dark:text-eoe-dark-text-sub">Hides your profile from view but leaves your content associated with your username.</p>
                        </div>
                        <ChevronRight className="inline-arrow-icon" />
                    </div>
                    <div
                        className="group flex justify-between items-center p-4 bg-eoe-bg-hover dark:bg-eoe-dark-bg-hover rounded-md cursor-pointer hover:bg-eoe-icon-hover dark:hover:bg-eoe-dark-icon-hover"
                        onClick={() => setOpenDeleteModal(true)}
                    >
                        <div className="flex flex-col items-start">
                            <span className="text-eoe-text dark:text-eoe-dark-text">Delete Account</span>
                            <p className="text-xs text-eoe-text dark:text-eoe-dark-text-sub">Permanently removes your profile and disassociates your username from your content.</p>
                        </div>
                        <ChevronRight className="inline-arrow-icon" />
                    </div>
                </div>
            </div>

            {/* Deactivate Modal */}
            {openDeactivateModal && (
                <Modal
                    isOpen={openDeactivateModal}
                    onClose={() => setOpenDeactivateModal(false)}
                    title="Deactivate Account"
                >
                    <div className="relative">
                        <p className="text-eoe-text dark:text-eoe-dark-text mb-4">
                            We understand that you need a break. If you log in after
                            deactivating your account, your account will
                            automatically be reactivated.
                        </p>
                        <div className="space-y-4">
                            <div className="form-control">
                                <label className="form-label">Reason</label>
                                <textarea
                                    className="input-text w-full"
                                    placeholder="Reason for deactivation (optional)"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                />
                            </div>
                            <div className="form-control">
                                <label className="form-label">Email Address</label>
                                <input
                                    type="email"
                                    className="input-text w-full"
                                    placeholder="Confirm email address"
                                    value={email}
                                    onChange={handleEmailConfirm}
                                />
                                {errorMessage && (
                                    <p className="error-message">{errorMessage}</p>
                                )}
                            </div>
                            <div className="flex justify-end space-x-2 mt-2">
                                <button
                                    className="btn-secondary"
                                    onClick={() => setOpenDeactivateModal(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={`btn-primary ${!isEmailValid ? 'btn-primary-disabled' : ''}`}
                                    onClick={handleDeactivate}
                                    disabled={!isEmailValid}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}

            {/* Delete Modal */}
            {openDeleteModal && (
                <Modal
                    isOpen={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    title="Delete Account"
                >
                    <div className="relative">
                        <p className="text-eoe-text dark:text-eoe-dark-text mb-4">
                            We're sad that you're leaving the fight.
                        </p>
                        <div className="space-y-4">
                            <div className="form-control">
                                <label className="form-label">Reason</label>
                                <textarea
                                    className="input-text w-full"
                                    placeholder="Reason for leaving (optional)"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                />
                            </div>
                            <div className="form-control">
                                <label className="form-label">Email Address</label>
                                <input
                                    type="email"
                                    className="input-text w-full"
                                    placeholder="Confirm email address"
                                    value={email}
                                    onChange={handleEmailConfirm}
                                />
                                {errorMessage && (
                                    <p className="error-message">{errorMessage}</p>
                                )}
                            </div>
                            <div className="flex justify-end space-x-2 mt-2">
                                <button
                                    className="btn-secondary"
                                    onClick={() => setOpenDeleteModal(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={`btn-primary ${!isEmailValid ? 'btn-primary-disabled' : ''
                                        }`}
                                    onClick={handleDelete}
                                    disabled={!isEmailValid}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default UserSettingsAccount
