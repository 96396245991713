import React, { useState } from 'react';
import { useUser } from 'util/UserContext';
import { Info } from 'lucide-react'; // Using lucide-react icons
import TextEditor from 'components/TextEditor';
import api from 'config/axiosConfig';
import { Impact, Scope } from 'util/constants';
import Modal from 'ui/Modal'; // Adjust the import path as necessary

interface Props {
  enemyId: number;
  onImpactAdded: (impact: Impact) => void;
  open: boolean;
  onClose: () => void;
}

const AddImpact: React.FC<Props> = ({ enemyId, onImpactAdded, open, onClose }) => {
  const { currentUser, setShowAuthModal } = useUser();
  const [referenceLink, setReferenceLink] = useState<string>('');
  const [scope, setScope] = useState<Scope | ''>('');
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [impactType, setImpactType] = useState<'impact_negative' | 'impact_positive'>('impact_negative');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleAddImpact = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!currentUser) {
      setShowAuthModal(true);
      return;
    }

    if (!title.trim() || !description.trim() || !referenceLink.trim() || !scope) {
      setError('All fields are required.');
      setSuccess('');
      return;
    }

    setIsSubmitting(true);
    setError('');
    setSuccess('');

    try {
      let standardizedLink = referenceLink.trim();

      // Ensure the link starts with "https://"
      if (!standardizedLink.startsWith('https://')) {
        standardizedLink = standardizedLink.replace(/^(.*?:\/\/)?/, 'https://');
      }

      const impactData = {
        reference_link: standardizedLink,
        scope,
        description: description.trim(),
        title: title.trim(),
        rel_type: impactType,
        user_id: currentUser.id,
      };

      const response = await api.post<Impact>(`/api/impacts/enemies/${enemyId}`, impactData);

      const newImpact = response.data;

      // Automatically upvote the impact
      await api.post(`/api/votes/impact/${newImpact.impact_id}`, {
        user_id: currentUser.id,
        rel_type: 'user_upvote',
      });

      setSuccess('Impact added successfully!');
      setError('');
      onImpactAdded(newImpact);
      setReferenceLink('');
      setScope('');
      setDescription('');
      setTitle('');
      onClose();
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        setShowAuthModal(true);
      } else {
        setError('Failed to add impact. Please try again.');
        setSuccess('');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseModal = () => {
    onClose();
    setReferenceLink('');
    setScope('');
    setDescription('');
    setTitle('');
    setError('');
    setSuccess('');
  };

  return (
    <Modal isOpen={open} onClose={handleCloseModal} title="Add Impact">
      <form onSubmit={handleAddImpact} className="flex flex-col space-y-4">
        {/* Title Field */}
        <div className="form-control">
          <label htmlFor="impact-title" className="form-label">
            Title
          </label>
          <input
            type="text"
            id="impact-title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="input-text block w-full px-3 py-2"
            placeholder="Enter impact title"
            required
          />
        </div>

        {/* Reference Link Field */}
        <div className="form-control">
          <label htmlFor="impact-reference-link" className="form-label">
            Reference Link
          </label>
          <input
            type="url"
            id="impact-reference-link"
            value={referenceLink}
            onChange={(e) => setReferenceLink(e.target.value)}
            className="input-text block w-full px-3 py-2"
            placeholder="https://en.wikipedia.org/wiki/Example"
            required
          />
        </div>

        {/* Scope Field */}
        <div className="form-control">
          <label htmlFor="impact-scope" className="form-label">
            Scope
          </label>
          <select
            id="impact-scope"
            value={scope || ''}
            onChange={(e) => setScope(e.target.value as Scope)}
            className="input-select block w-full px-3 py-2"
            required
          >
            <option value="" disabled>
              Select Scope
            </option>
            <option value="Local">Local</option>
            <option value="National">National</option>
            <option value="Global">Global</option>
          </select>
        </div>

        {/* Impact Type Field */}
        <div className="form-control">
          <label htmlFor="impact-type" className="form-label">
            Impact Type
          </label>
          <select
            id="impact-type"
            value={impactType}
            onChange={(e) =>
              setImpactType(e.target.value as 'impact_negative' | 'impact_positive')
            }
            className="input-select block w-full px-3 py-2"
            required
          >
            <option value="impact_negative">Negative</option>
            <option value="impact_positive">Positive</option>
          </select>
        </div>

        {/* Description Field */}
        <div className="form-control">
          <label htmlFor="impact-description" className="form-label">
            Description
          </label>
          <TextEditor
            value={description}
            onChange={setDescription}
            editorType="entity"
          />
        </div>

        {/* Error and Success Messages */}
        {(error || success) && (
          <div className="flex items-center space-x-2">
            {error && (
              <div className="flex items-center text-red-500 text-sm">
                <Info className="mr-1" />
                <span>{error}</span>
              </div>
            )}
            {success && (
              <div className="flex items-center text-green-500 text-sm">
                <Info className="mr-1" />
                <span>{success}</span>
              </div>
            )}
          </div>
        )}

        {/* Action Buttons */}
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={handleCloseModal}
            className="btn-secondary"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`btn-primary ${isSubmitting ? 'btn-primary-disabled' : ''}`}
          >
            {isSubmitting ? 'Adding...' : 'Add Impact'}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddImpact;