import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface ScrollButtonProps {
  direction: 'left' | 'right';
  onClick: () => void;
  visible: boolean;
}

const ScrollButton: React.FC<ScrollButtonProps> = ({
  direction,
  onClick,
  visible,
}) => {
  return (
    <button
      onClick={onClick}
      aria-label={`Scroll ${direction}`}
      className={`absolute top-1/2 transform -translate-y-1/2 ${
        direction === 'left' ? 'left-0' : 'right-0'
      } ${
        visible ? 'flex' : 'hidden'
      } items-center justify-center size-6 rounded-full border-0 bg-eoe-icon dark:bg-eoe-dark-icon text-eoe-icon-stroke dark:text-eoe-dark-icon-stroke hover:bg-eoe-icon-hover dark:hover:bg-eoe-dark-icon-hover hover:text-eoe-icon-stroke-hover dark:hover:text-eoe-dark-icon-stroke-hover transition-all duration-200 ease-in-out`}
    >
      {direction === 'left' ? (
        <ChevronLeft className="w-5 h-5" />
      ) : (
        <ChevronRight className="w-5 h-5" />
      )}
    </button>
  );
};

export default ScrollButton;
