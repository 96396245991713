import React, { useState, useRef, useEffect } from 'react';
import GoogleSignin from './GoogleSignin';
import api from 'config/axiosConfig';
import { useUser } from 'util/UserContext';
import Modal from 'ui/Modal';

interface AuthModalProps {
    show: boolean;
    onClose: () => void;
}

const AuthModal: React.FC<AuthModalProps> = ({ show, onClose }) => {
    const { refreshUser, setShowUsernameModal } = useUser();
    const [authError, setAuthError] = useState<string>('');
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleCredentialResponse = async (response: any): Promise<void> => {
        try {
            const { credential } = response;
            const result = await api.post('/auth/google/callback', {
                token: credential,
            });

            // Let's assume your API returns something like:
            // { status: 'existing' } or { status: 'new' }
            const userStatus = result.status;

            // Refresh the user to ensure currentUser is up-to-date.
            await refreshUser();

            if (userStatus === 201) {
                // New user needs to set username
                onClose(); // Close AuthModal first
                setShowUsernameModal(true); // Open UsernameModal separately
            } else if (userStatus === 200) {
                // Existing user logged in successfully
                // If no username needed, just close the AuthModal
                onClose();
            } else {
                setAuthError('Failed to authenticate. Please try again.');
            }
        } catch (error) {
            console.error('Error handling credential response:', error);
            setAuthError('Failed to authenticate. Please try again.');
        }
    };

    if (!show) {
        return null;
    }

    return (
        <Modal isOpen={show} onClose={onClose} title="Join the Fight">
            <div ref={modalRef} className="space-y-6">
                <p className="text-left text-sm text-gray-600 dark:text-eoe-dark-text-meta">
                    By continuing, you agree to our{' '}
                    <a
                        href="https://societea.io/policies/user-agreement"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="external-link"
                    >
                        User Agreement
                    </a>{' '}
                    and acknowledge that you understand the{' '}
                    <a
                        href="/policies/privacy-policy"
                        className="external-link"
                    >
                        Privacy Policy
                    </a>
                    .
                </p>

                {authError && (
                    <div className="rounded bg-red-100 p-3 text-sm text-red-700 dark:bg-red-900/20 dark:text-red-400">
                        {authError}
                    </div>
                )}

                <div className="w-full">
                    <GoogleSignin
                        handleCredentialResponse={handleCredentialResponse}
                    />
                </div>

                <footer className="text-left">
                    <a
                        href="/"
                        className="internal-link text-sm text-gray-500 hover:text-gray-700 dark:text-eoe-dark-text-meta dark:hover:text-eoe-dark-text"
                    >
                        Return to Enemies of Earth
                    </a>
                </footer>
            </div>
        </Modal>
    );
};

export default AuthModal;
