import React, { useEffect } from 'react'

interface ModalProps {
    isOpen: boolean
    onClose: () => void
    title: string
    children: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
    useEffect(() => {
        if (isOpen) {
            // Prevent background scrolling
            document.body.style.overflow = 'hidden';

            // Optionally add `inert` to your main app container if you have a unique ID for it
            // const appContainer = document.getElementById('app-container');
            // if (appContainer) {
            //     appContainer.setAttribute('inert', '');
            // }

            // Trap focus inside the modal if necessary
            // (This can be done with a focus trap library or custom code)
        } else {
            document.body.style.overflow = '';
            // const appContainer = document.getElementById('app-container');
            // if (appContainer) {
            //     appContainer.removeAttribute('inert');
            // }
        }

        // Cleanup if the modal unmounts
        return () => {
            document.body.style.overflow = '';
            // const appContainer = document.getElementById('app-container');
            // if (appContainer) {
            //     appContainer.removeAttribute('inert');
            // }
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 z-[50] flex items-center justify-center bg-black bg-opacity-50 mt-[56px] p-2"
            role="dialog"
            aria-modal="true"
            aria-labelledby={`${title}-title`}
            // Ensure it covers the entire viewport, no offsets that expose background
        >
            <div className="relative w-full max-w-md max-h-[calc(100vh-72px)] overflow-auto bg-eoe-bg dark:bg-eoe-dark-bg-modal p-6 rounded-lg">
                <button
                    className="modal-close-button"
                    onClick={onClose}
                    aria-label={`Close ${title} Modal`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="modal-close-icon"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
                <h2 id={`${title}-title`} className="text-eoe-text-heading dark:text-eoe-dark-text-heading text-xl font-semibold mb-4">
                    {title}
                </h2>
                {children}
            </div>
        </div>
    )
}

export default Modal;
