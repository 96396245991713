import React, { useState, useRef } from 'react';
import { useUser } from 'util/UserContext';
import AvatarEditor from 'react-avatar-editor';
import api from 'config/axiosConfig';
import Modal from 'ui/Modal'; // Adjust the import path as necessary

interface Props {
    onClose: () => void;
}

const UserAvatarModal: React.FC<Props> = ({ onClose }) => {
    const { currentUser } = useUser();
    const [image, setImage] = useState<File | null>(null);
    const [scale, setScale] = useState<number>(1);
    const [rotate, setRotate] = useState<number>(0);
    const [avatarKey, setAvatarKey] = useState<string | undefined>(
        currentUser?.properties_public?.avatar_key
    );
    const editorRef = useRef<AvatarEditor>(null);
    const IMAGE_CDN = process.env.REACT_APP_IMAGE_CDN;

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.[0]) {
            setImage(event.target.files[0]);
        }
    };

    const handleSave = async () => {
        if (editorRef.current) {
            editorRef.current.getImageScaledToCanvas().toBlob(async (blob) => {
                if (blob) {
                    const formData = new FormData();
                    formData.append('image', blob, 'profile-picture.png');

                    try {
                        const response = await api.post('/api/user/avatar', formData);
                        setAvatarKey(response.data.key);
                        setImage(null);
                        // Optionally, you can provide user feedback here
                    } catch (error) {
                        console.error('Error uploading profile picture:', error);
                        // Optionally, set an error message state to display to the user
                    }
                }
            }, 'image/png');
        }
    };

    const handleClose = () => {
        onClose();
        window.location.reload();
    };

    return (
        <Modal isOpen={true} onClose={handleClose} title="Avatar Image">
            <div className="space-y-4">
                {!image ? (
                    <div className="flex flex-col items-center space-y-4">
                        <img
                            src={avatarKey ? `${IMAGE_CDN}/${avatarKey}` : undefined}
                            alt={currentUser?.username || "Profile"}
                            className="w-24 h-24 rounded-full object-cover"
                        />
                        <label className="btn-primary cursor-pointer">
                            Select a New Image
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                        </label>                        
                        <label className="btn-secondary cursor-pointer" onClick={handleClose}>
                            Close
                        </label>
                    </div>
                ) : (
                    <div className="flex flex-col items-center space-y-4">
                        <AvatarEditor
                            ref={editorRef}
                            image={image}
                            border={50}
                            borderRadius={125}
                            color={[0, 0, 0, 0]}
                            scale={scale}
                            rotate={rotate}
                        />
                        <div className="max-w-full space-y-4">
                            <div className="flex items-center space-x-2">
                                <span className="text-eoe-text dark:text-eoe-dark-text">Zoom:</span>
                                <input
                                    type="range"
                                    min={1}
                                    max={3}
                                    step={0.01}
                                    value={scale}
                                    onChange={(e) => setScale(Number(e.target.value))}
                                    className="max-w-full h-2 bg-eoe-border dark:bg-eoe-dark-border rounded-lg appearance-none cursor-pointer"
                                />
                            </div>
                            <div className="flex items-center space-x-2">
                                <span className="text-eoe-text dark:text-eoe-dark-text">Rotate:</span>
                                <input
                                    type="range"
                                    min={0}
                                    max={360}
                                    step={1}
                                    value={rotate}
                                    onChange={(e) => setRotate(Number(e.target.value))}
                                    className="max-w-full h-2 bg-eoe-border dark:bg-eoe-dark-border rounded-lg appearance-none cursor-pointer"
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex justify-end space-x-2">
                    {image && (
                        <button
                            className="btn-secondary"
                            onClick={() => setImage(null)}
                        >
                            Cancel
                        </button>
                    )}
                    {image && (
                        <button
                            className={`btn-primary ${!image ? 'btn-primary-disabled' : ''}`}
                            onClick={handleSave}
                            disabled={!image}
                        >
                            Save
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default UserAvatarModal;