import React, { useEffect, useState } from 'react'
import { useUser } from 'util/UserContext'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import UserSettingsAccount from './UserSettingsAccount'
import UserSettingsProfile from './UserSettingsProfile'
import { setDocumentTitle } from 'util/pageTitles'
import { capitalizeFirstLetter } from 'util/textUtils'

const UserSettings: React.FC = () => {
    const { currentUser, awaitingUser } = useUser()
    const navigate = useNavigate()
    const { tab } = useParams<{ tab: string }>()
    const [selectedTab, setSelectedTab] = useState<string>(tab || 'account')

    useEffect(() => {
        setDocumentTitle(`Settings - ${capitalizeFirstLetter(selectedTab)}`)
    }, [selectedTab])

    useEffect(() => {
        if (!awaitingUser && !currentUser) {
            navigate('/login', { replace: true })
        }
    }, [currentUser, awaitingUser, navigate])

    const handleTabChange = (newTab: string) => {
        setSelectedTab(newTab)
    }

    // Update selectedTab when URL changes
    useEffect(() => {
        if (tab) {
            setSelectedTab(tab)
        }
    }, [tab])

    return (
        <div className="max-w-4xl mx-2 my-2">
            <h1 className="text-eoe-text-heading dark:text-eoe-dark-text-heading text-2xl font-bold">Settings</h1>
            {/* Tabs Navigation */}
            <div className="my-2">
                <nav className="flex space-x-4 overflow-x-auto whitespace-nowrap" aria-label="Settings Tabs">
                    <NavLink
                        to="/settings/account"
                        className={({ isActive }) =>
                            isActive || selectedTab === 'account'
                                ? 'tab-active'
                                : 'tab-inactive'
                        }
                        onClick={() => handleTabChange('account')}
                        role="tab"
                        aria-selected={selectedTab === 'account'}
                        aria-controls="account-panel"
                        id="account-tab"
                    >
                        Account
                    </NavLink>
                    <NavLink
                        to="/settings/profile"
                        className={({ isActive }) =>
                            isActive || selectedTab === 'profile'
                                ? 'tab-active'
                                : 'tab-inactive'
                        }
                        onClick={() => handleTabChange('profile')}
                        role="tab"
                        aria-selected={selectedTab === 'profile'}
                        aria-controls="profile-panel"
                        id="profile-tab"
                    >
                        Profile
                    </NavLink>
                </nav>
            </div>

            {/* Tabs Content */}
            <div className="mt-6">
                {selectedTab === 'account' && (
                    <div
                        role="tabpanel"
                        id="account-panel"
                        aria-labelledby="account-tab"
                        className="focus:outline-none"
                    >
                        <UserSettingsAccount />
                    </div>
                )}
                {selectedTab === 'profile' && (
                    <div
                        role="tabpanel"
                        id="profile-panel"
                        aria-labelledby="profile-tab"
                        className="focus:outline-none"
                    >
                        <UserSettingsProfile />
                    </div>
                )}
            </div>
        </div>
    )
}

export default UserSettings