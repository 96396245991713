import React from 'react';
import { UserProvider, useUser } from 'util/UserContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Feed from 'components/Feed'
import AuthLogout from 'components/Auth/AuthLogout'
import AddEnemy from 'components/Enemies/AddEnemy'
import EnemyProfile from 'components/Enemies/EnemyProfile'
import EnemiesList from 'components/Enemies/EnemiesList'
import UserProfile from 'components/User/UserProfile'
import SolutionProfile from 'components/Solutions/SolutionProfile'
import ImpactProfile from 'components/Impacts/ImpactProfile'
import Header from 'components/Header'
import UserSettings from 'components/User/UserSettings'
import FeedbackPage from 'components/Feedback'
import FeedbackProfile from 'components/Feedback/FeedbackProfile'
import AdminPortal from 'components/Admin/AdminPortal'
import PrivacyPolicy from './PrivacyPolicy'
import AuthModal from 'components/Auth/AuthModal'
import AuthLogin from 'components/Auth/AuthLogin'
import WelcomeModal from 'components/WelcomeModal';
import UsernameModal from 'components/User/UsernameModal';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import {
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles'
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles'
import CssBaseline from '@mui/material/CssBaseline'
import joyTheme from 'ui/joyTheme'
import materialTheme from 'ui/materialTheme'
import { ThemeProvider } from './context/ThemeContext';

const App = () => {
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);

    useEffect(() => {
        const hasSeenWelcome = Cookies.get('hasSeenWelcome');
        
        if (!hasSeenWelcome) {
            setShowWelcomeModal(true);
            Cookies.set('hasSeenWelcome', 'true', { expires: 365 });
        }
    }, []);

    const handleCloseWelcomeModal = () => {
        setShowWelcomeModal(false);
    };

    return (
        <UserProvider>
          <div className="min-h-screen bg-white dark:!bg-eoe-dark-bg transition-colors duration-200 dark:text-gray-500">
            <Router>
              <ThemeProvider>
                <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
                  <JoyCssVarsProvider theme={joyTheme}>
                    <CssBaseline enableColorScheme />
                    <div className="App h-full pt-14 xl:ml-[260px] transition-colors duration-200">
                      <Header />
                      <div>
                        <Routes>
                          <Route path="/" element={<Feed />} />
                          <Route path="/:entityType" element={<Feed />} />
                          <Route path="/:entityType/:sort" element={<Feed />} />
                          <Route path="/logout" element={<AuthLogout />} />
                          <Route path="/login" element={<AuthLogin />} />
                          <Route
                            path="/add-enemy"
                            element={<AddEnemy open={false} onClose={() => {}} />}
                          />
                          <Route path="/enemy/:name/" element={<EnemyProfile />} />
                          <Route path="/enemy/:name/:tab" element={<EnemyProfile />} />
                          <Route path="/enemy/:name/comments/:commentId" element={<EnemyProfile />} />
                          <Route path="/enemies" element={<EnemiesList sort="new" />} />
                          <Route path="/settings/" element={<UserSettings />} />
                          <Route path="/settings/:tab" element={<UserSettings />} />
                          <Route path="/user" element={<UserProfile />} />
                          <Route path="/user/:username" element={<UserProfile />} />
                          <Route path="/user/:username/:tab" element={<UserProfile />} />
                          <Route path="/solution/:id" element={<SolutionProfile />} />
                          <Route path="/solution/:id/:tab" element={<SolutionProfile />} />
                          <Route
                            path="/solution/:id/comments/:commentId"
                            element={<SolutionProfile />}
                          />
                          <Route path="/impact/:id" element={<ImpactProfile />} />
                          <Route path="/impact/:id/:tab" element={<ImpactProfile />} />
                          <Route
                            path="/impact/:id/comments/:commentId"
                            element={<ImpactProfile />}
                          />
                          <Route path="/feedback" element={<FeedbackPage />} />
                          <Route path="/feedback/:id" element={<FeedbackProfile />} />
                          <Route path="/feedback/:id/:tab" element={<FeedbackProfile />} />
                          <Route
                            path="/feedback/:id/comments/:commentId"
                            element={<FeedbackProfile />}
                          />
                          <Route path="/admin" element={<AdminPortal />} />
                          <Route path="/admin/:tab" element={<AdminPortal />} />
                          <Route
                            path="/policies/privacy-policy"
                            element={<PrivacyPolicy />}
                          />
                        </Routes>
                      </div>
                      <AuthModalWrapper />
                      <UsernameModalWrapper />
                      <WelcomeModal 
                        open={showWelcomeModal} 
                        onClose={handleCloseWelcomeModal} 
                      />
                    </div>
                  </JoyCssVarsProvider>
                </MaterialCssVarsProvider>
              </ThemeProvider>
            </Router>
          </div>
        </UserProvider>
    )
}

const AuthModalWrapper = () => {
  const { showAuthModal, setShowAuthModal } = useUser();

  return (
    <>
      {showAuthModal && (
        <AuthModal show={showAuthModal} onClose={() => setShowAuthModal(false)} />
      )}
    </>
  );
};

const UsernameModalWrapper = () => {
  const { showUsernameModal, setShowUsernameModal } = useUser();

  return (
    <>
      {showUsernameModal && (
        <UsernameModal onClose={() => setShowUsernameModal(false)} />
      )}
    </>
  );
};

export default App;
