import React, { useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import SideNavBar from './SideNavBar'
import eoeLogo from 'assets/eoe_earth_border.svg'
import api from 'config/axiosConfig'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/joy/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import SettingsIcon from '@mui/icons-material/Settings'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import AddEnemy from 'components/Enemies/AddEnemy'
import { Plus } from 'lucide-react'
import { Enemy } from 'util/constants'
import { useUser } from 'util/UserContext';

const Header = () => {
    const { currentUser, setShowAuthModal } = useUser();
    const navigate = useNavigate()
    const [searchQuery, setSearchQuery] = useState('');
    const [enemySuggestions, setEnemySuggestions] = useState<Enemy[]>([]);
    const [wikiSuggestions, setWikiSuggestions] = useState<any[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState<HTMLButtonElement | null>()
    const [openAddEnemyModal, setOpenAddEnemyModal] = useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [prepopulateData, setPrepopulateData] = useState<{ name: string; wikipediaLink: string } | null>(null)
    const [isInputFocused, setIsInputFocused] = useState(false);

    
    const searchInputRef = useRef<HTMLInputElement>(null);
    const IMAGE_CDN = process.env.REACT_APP_IMAGE_CDN;

    const settings = [
        {
            label: (
                <>
                    My Profile
                    <br />
                    <span className="text-xs md:text-sm">{currentUser?.username}</span>
                </>
            ),
            icon: (
                <Avatar
                    alt={currentUser?.username || "Profile"}
                    src={currentUser?.properties_public?.avatar_key ? `${IMAGE_CDN}/${currentUser.properties_public.avatar_key}` : undefined}
                    sx={{ width: 32, height: 32, marginRight: 1 }}
                />
            ),
            link: `/user/${currentUser?.username}`,
            tooltip: 'View your profile',
        },
        {
            label: 'Settings',
            icon: (
                <SettingsIcon
                    fontSize="large"
                    sx={{ color: '#555555', mr: 1 }}
                />
            ),
            link: '/settings/',
            tooltip: 'View your settings',
        },
        {
            label: 'Logout',
            icon: (
                <LogoutIcon fontSize="large" sx={{ color: '#555555', mr: 1 }} />
            ),
            link: '/logout',
            tooltip: 'Logout',
        },
    ]

    const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchQuery(value);
    
        if (value.length > 1) {
          try {
            // Fetch enemy suggestions
            const response = await api.get(`/api/enemies?search=${encodeURIComponent(value)}`);
            const enemyResults = response.data;
            setEnemySuggestions(enemyResults);
    
            // If no enemy results, fetch Wikipedia suggestions
            if (enemyResults.length === 0) {
              const wikiResponse = await fetch(
                `https://en.wikipedia.org/w/api.php?action=opensearch&search=${encodeURIComponent(
                  value
                )}&limit=5&namespace=0&format=json&origin=*`
              );
              const data = await wikiResponse.json();
              const wikiResults = data[1].map((title: string, index: number) => ({
                title,
                link: data[3][index],
              }));
              setWikiSuggestions(wikiResults);
            } else {
              setWikiSuggestions([]);
            }
          } catch (error) {
            console.error('Error fetching search results:', error);
          }
        } else {
          setEnemySuggestions([]);
          setWikiSuggestions([]);
        }
        setShowSuggestions(true);
      };
    
      const handleSearchSelect = (enemyName: string) => {
        navigate(`/enemy/${encodeURIComponent(enemyName.replace(/ /g, '_'))}`);
        setShowSuggestions(false);
        setSearchQuery('');
      };
    
      const handleWikiSelect = (wikiSuggestion: { title: string; link: string }) => {
        setPrepopulateData({ name: wikiSuggestion.title, wikipediaLink: wikiSuggestion.link });
        setOpenAddEnemyModal(true);
        setShowSuggestions(false);
        setSearchQuery('');
      };

    const handleAddEnemyButtonClick = () => {
        if (!currentUser) {
            setShowAuthModal(true)
        } else if (openAddEnemyModal) {
            setOpenAddEnemyModal(false)
        } else {
            setOpenAddEnemyModal(true)
            setPrepopulateData(null);
        }
    }

    const handleLoginButtonClick = () => {
        if (!currentUser) {
            setShowAuthModal(true)
        } else {
            navigate('/')
        }
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return
        }
        setIsDrawerOpen(open)
        if (open) {
            document.body.classList.add('drawer-open')
        } else {
            document.body.classList.remove('drawer-open')
        }
    }

    return (
        <div className="dark:bg-eoe-dark-bg">
            <AppBar
                position="fixed"
                variant="outlined"
                elevation={0}
                className="bg-white dark:bg-eoe-dark-bg transition-colors duration-200 dark:border-eoe-dark-border-main"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    border: 'none',
                    borderBottom: '0.1rem solid',
                    borderColor: 'rgb(200, 200, 200)',
                    backgroundColor: 'transparent',
                }}
            >
                <Toolbar disableGutters className="flex px-2">
                    <div className="flex gap-xs shrink-0 items-center justify-start">
                            <IconButton
                                size="medium"
                                edge="start"
                                aria-label="side navigation menu"
                                className="mr-1 hover:bg-gray-300 dark:hover:bg-gray-700 dark:text-gray-300 rounded-full size-9 ml-0 xl:hidden"
                                onClick={toggleDrawer(!isDrawerOpen)}
                            >
                                <MenuOutlinedIcon />
                            </IconButton>
                        <Tooltip title="Return to home page" arrow>
                                <a href="/" className="no-underline flex items-center">
                                    <span className="logo-earth-container mr-2 flex-1 items-center">
                                        <img
                                            src={eoeLogo}
                                            alt="Enemies of Earth Logo"
                                            className="logo size-10 bg-white dark:bg-eoe-dark-bg"
                                        />
                                    </span>
                                    <span className="logo-text-container hidden md:flex items-center">
                                        <img
                                            src='/eoe_logo_text.svg'
                                            alt="Enemies of Earth Logo"
                                            className="logo-text size-16"
                                        />
                                    </span>
                                </a>
                        </Tooltip>
                    </div>

                    {/* Middle: Search Input */}
                    <div
                        className={`relative transition-all duration-200 mx-0 ${
                        !isInputFocused ? 'flex-grow' : 'flex-shrink-0 basis-auto'
                        }`}
                    >
                        <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        ref={searchInputRef}
                        placeholder="Search enemies..."
                        onFocus={() => setIsInputFocused(true)}
                        onBlur={() => setTimeout(() => setIsInputFocused(false), 100)}
                        className="w-full bg-gray-200 dark:bg-eoe-dark-input rounded-full px-4 py-2 text-sm text-eoe-text dark:text-eoe-dark-text focus:outline-none transition-all duration-200"
                        />
                        {showSuggestions && (enemySuggestions.length > 0 || wikiSuggestions.length > 0) && (
                            <ul className="absolute z-10 mt-1 w-full bg-eoe-bg dark:bg-eoe-dark-bg border border-eoe-border-main dark:border-eoe-dark-border-main rounded-md shadow-lg max-h-60 overflow-auto">
                                {enemySuggestions.map((enemy) => (
                                <li
                                    key={enemy.id}
                                    className="px-3 py-2 hover:bg-eoe-icon dark:hover:bg-eoe-dark-icon cursor-pointer text-eoe-text dark:text-eoe-dark-text"
                                    onClick={() => handleSearchSelect(enemy.name)}
                                >
                                    {enemy.name}
                                </li>
                                ))}
                                {enemySuggestions.length === 0 &&
                                wikiSuggestions.map((wiki, index) => (
                                    <li
                                    key={`wiki-${index}`}
                                    className="px-3 py-2 hover:bg-eoe-bg-hover dark:hover:bg-eoe-dark-bg-hover cursor-pointer italic flex justify-between items-center text-eoe-text dark:text-eoe-dark-text"
                                    onClick={() => handleWikiSelect(wiki)}
                                    >
                                    {wiki.title}
                                    <Plus className="w-4 h-4 text-eoe-gray dark:text-eoe-dark-text flex-shrink-0" />
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    {currentUser ? (
            <div className="flex items-center">
              <button
                className="rounded-full pr-2 pl-1 py-2 mx-1 flex justify-center items-center gap-1 text-sm text-eoe-gray dark:text-eoe-dark-text font-semibold hover:bg-eoe-red hover:text-white cursor-pointer transition-colors"
                onClick={handleAddEnemyButtonClick}
              >
                <Plus className="w-5 h-5" />
                <span>Enemy</span>
              </button>
              {openAddEnemyModal && (
                <AddEnemy
                  open={openAddEnemyModal}
                  onClose={() => setOpenAddEnemyModal(false)}
                  prepopulateData={prepopulateData}
                />
              )}
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open profile menu">
                                    <IconButton
                                        onClick={handleOpenUserMenu}
                                        sx={{ p: 0 }}
                                    >
                                        <Avatar 
                                            alt={currentUser?.username || "Profile"}
                                            src={currentUser?.properties_public?.avatar_key ? `${IMAGE_CDN}/${currentUser.properties_public.avatar_key}` : undefined}
                                            sx={{ width: 32, height: 32 }}/>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={() => handleCloseUserMenu()}
                                >
                                    {settings.map((userButton) => (
                                        <MenuItem
                                            key={`${userButton.label}`}
                                            component={Link}
                                            to={userButton.link}
                                            onClick={handleCloseUserMenu}
                                        >
                                            <Tooltip title={userButton.tooltip} placement="left" arrow>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {userButton.icon}
                                                <Typography
                                                    textAlign="left"
                                                    sx={{ ml: 1 }}
                                                >
                                                    {userButton.label}
                                                </Typography>
                                            </Box>
                                            </Tooltip>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </div>
                    ) : (
                        <Tooltip title="Login or sign up" arrow>
                            <button
                                className="rounded-3xl px-4 py-2 mx-2 text-sm dark:text-eoe-dark-text bg-eoe-red hover:bg-eoe-red-hov text-white cursor-pointer transition-colors"
                                onClick={handleLoginButtonClick}
                            >
                                Login
                            </button>
                        </Tooltip>
                    )}
                </Toolbar>
            </AppBar>
            <SideNavBar isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} onClick={() => setIsDrawerOpen(false)}/>
        </div>
    )
}

export default Header
