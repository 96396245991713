import React, { useState, useEffect } from 'react';
import { useUser } from 'util/UserContext';
import api from 'config/axiosConfig';
import { Info } from 'lucide-react'; // Using lucide-react icons
import Modal from 'ui/Modal'; // Adjust the import path as necessary
import { Enemy } from 'util/constants';

interface Props {
    solutionId: string;
    onEnemyAdded: (value: Enemy) => void;
    open: boolean;
    onClose: (value: boolean) => void;
}

const AddEnemyToSolution: React.FC<Props> = ({ solutionId, onEnemyAdded, open, onClose }) => {
    const { currentUser, setShowAuthModal } = useUser();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchResults, setSearchResults] = useState<Enemy[]>([]);
    const [selectedEnemy, setSelectedEnemy] = useState<Enemy | null>(null);
    const [error, setError] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (searchQuery.length > 0) {
            const fetchEnemies = async () => {
                try {
                    const response = await api.get(`/api/enemies?search=${encodeURIComponent(searchQuery)}`);
                    setSearchResults(response.data);
                } catch (error) {
                    console.error('Error fetching enemies:', error);
                }
            };
            fetchEnemies();
        } else {
            setSearchResults([]);
        }
    }, [searchQuery]);

    const handleAddEnemy = async () => {
        if (!selectedEnemy) {
            setError('Please select an enemy.');
            return;
        }

        if (!currentUser) {
            setShowAuthModal(true);
            return;
        }

        setIsSubmitting(true);
        setError('');

        try {
            await api.put(`/api/solutions/link/${solutionId}/${selectedEnemy.id}`);
            onEnemyAdded(selectedEnemy);
            setSearchQuery('');
            setSearchResults([]);
            setSelectedEnemy(null);
            onClose(false);
        } catch (error) {
            console.error('Error adding enemy to solution:', error);
            setError('Failed to add enemy to solution. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCloseModal = () => {
        onClose(false);
        setSearchQuery('');
        setSearchResults([]);
        setSelectedEnemy(null);
        setError('');
    };

    return (
        <Modal isOpen={open} onClose={handleCloseModal} title="Suggest Solution for Enemy">
            <form onSubmit={handleAddEnemy} className="flex flex-col space-y-4">
                {/* Search Input */}
                <div className="form-control">
                    <label htmlFor="enemy-search" className="form-label">
                        Search Enemy
                    </label>
                    <input
                        id="enemy-search"
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search enemies..."
                        className="input-text block w-full px-3 py-2"
                        autoComplete="off"
                    />
                </div>

                {/* Search Results */}
                {searchResults.length > 0 && (
                    <ul className="search-results">
                        {searchResults.map((enemy) => (
                            <li
                                key={enemy.id}
                                className={`search-result ${
                                    selectedEnemy && selectedEnemy.id === enemy.id
                                        ? 'search-result-selected'
                                        : ''
                                }`}
                                onClick={() => {
                                    setSelectedEnemy(enemy);
                                    setSearchQuery(enemy.name);
                                    setSearchResults([]);
                                    setError('');
                                }}
                            >
                                {enemy.name}
                            </li>
                        ))}
                    </ul>
                )}

                {/* Error Message */}
                {error && (
                    <div className="text-red-500 text-sm flex items-center">
                        <Info className="mr-1" />
                        {error}
                        {error.includes('already exists') && selectedEnemy && (
                            <a
                                href={`/enemy/${encodeURIComponent(selectedEnemy.name.replace(/ /g, '_'))}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="internal-link underline hover:no-underline ml-1"
                            >
                                View Enemy
                            </a>
                        )}
                    </div>
                )}

                {/* Action Buttons */}
                <div className="flex justify-end space-x-2">
                    <button
                        type="button"
                        onClick={handleCloseModal}
                        className="btn-secondary"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={!selectedEnemy || isSubmitting}
                        className={`btn-primary ${!selectedEnemy || isSubmitting ? 'btn-primary-disabled' : ''}`}
                    >
                        {isSubmitting ? 'Adding...' : 'Submit'}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default AddEnemyToSolution;