import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from 'config/axiosConfig';
import { transformHtmlContent } from 'util/transformHtmlContent';
import { ChevronsDown, ChevronsUp } from 'lucide-react';
import EntityActions from 'components/Entities/EntityActions';
import Report from 'components/Entities/Report';
import {
  Menu,
  MenuItem,
  Tooltip,
  Modal,
  Chip,
  ModalDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Avatar
} from '@mui/joy';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Solution } from 'util/constants';
import { formatDate, formatRelativeTime } from 'config/dayjsConfig';
import { useUser } from 'util/UserContext';
import EntityThumbnail from 'components/Entities/EntityThumbnail';

interface Props {
  solution: Solution;
  showMeta?: boolean;
  truncateDescription?: boolean;
  isSolutionProfilePage?: boolean;
  showActions?: boolean;
  onVote?: () => void;
}

const SolutionCard = ({
  solution,
  showMeta = true,
  truncateDescription = true,
  isSolutionProfilePage = false,
}: Props) => {
  const { currentUser } = useUser();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const solutionHref = `/solution/${encodeURIComponent(solution.id.toString())}`;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const moreIconRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const reportModalRef = useRef<HTMLDivElement>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const TruncateMarkup = require('react-truncate-markup').default;

  const IMAGE_CDN = process.env.REACT_APP_IMAGE_CDN;

  const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const element = event.target as HTMLElement;
    if (element.tagName.toLowerCase() === 'a') return;    
    if (element.classList.contains('read-more')) return;
    if (!isSolutionProfilePage) {
      navigate(solutionHref);
    } else {
      event.preventDefault();            
      if (element.tagName.toLowerCase() === 'img') {
          event.stopPropagation();
          window.open(solution.reference_link, '_blank', 'noopener,noreferrer');
      } else {            
          return;
      }
    }
  };

  const handleDescriptionClick = (event: React.MouseEvent) => {
    if (!truncateDescription || !isTruncated) return;
    event.preventDefault();
    if (isSolutionProfilePage && truncateDescription) {
      setIsExpanded(!isExpanded);
    } else {
      return;
    }
  };

  const handleReadMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsExpanded(!isExpanded);
    event.stopPropagation(); // Prevent the click from bubbling up to the card
  };

  const handleMenuToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorEl) {
      setAnchorEl(null); // Close the menu if it's already open
    } else {
      setAnchorEl(event.currentTarget); // Open the menu
    }
  };

  const handleReportClick = () => {
    handleMenuClose(); // Close the menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsReportModalOpen(false);
  };

  const handleEdit = () => {
    navigate(`/solution/${solution.id}?edit=true`);
  };

  const handleDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await api.delete(`/api/solutions/id/${solution.id}`);
      setIsDeleteDialogOpen(false);
      if (isSolutionProfilePage) {
        navigate('/');
      } else {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error deleting solution:', error);
    }
  };

  const cancelDelete = () => {
    setIsDeleteDialogOpen(false);
  };

  // Close the menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const element = event.target as HTMLElement;
      if (
        anchorEl &&
        !moreIconRef.current?.contains(element) &&
        !menuRef.current?.contains(element) &&
        !reportModalRef.current?.contains(element)
      ) {
        handleMenuClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl]);

  const truncateEllipsis = (node: any) => {
    return (
      <span className="read-more">
      <button 
        className="read-more-border w-full text-sm rounded-md border-b-2 justify-center items-center px-1"
        onClick={handleReadMoreClick}
      >
      <span className="flex justify-center items-center">
          <ChevronsDown className="flex justify-center items-center"/>
          </span>
        </button>
          </span>
    );
  };

  const renderDescription = () => {
    if (truncateDescription && !isExpanded) {
      return (
      <TruncateMarkup lines={10} tokenize="words" ellipsis={truncateEllipsis} onTruncate={(wasTruncated: any) => setIsTruncated(wasTruncated)}>
        <div>
          {transformHtmlContent(solution.description || '')}
        </div>
      </TruncateMarkup>
      );
    } else {
      return (
          <div>
              {transformHtmlContent(solution.description || '')}
              {isTruncated && (
                  <>
                      <span className="flex">
                          <button 
                              className="read-more-border w-full text-sm rounded-md border-b-2 justify-center items-center px-1"
                              onClick={handleReadMoreClick}
                          >
                              <span className="flex justify-center items-center ">
                                  <ChevronsUp className="flex justify-center items-center" />
                              </span>
                          </button>
                      </span>
                  </>
              )}
          </div>
      );
    }
  };

  return (
    <div className="card-container my-1">
      <article>
        <div className="card-main rounded-lg flex flex-col px-4 py-2 cursor-pointer flex-wrap relative">
          <div className="entity-actions-container order-last md:w-80">
            <EntityActions entity={solution} entityType="solution" />
          </div>
          <div className="card-content order-first md:ml-4">
            {showMeta && (
              <div className="card-meta text-sm flex items-center justify-between relative">
              <div className="flex items-center">
                <Tooltip title={`See ${solution.username}'s profile`} arrow>
                  <Link
                    to={`/user/${solution.username}`}
                    className="card-username"
                  >
                    <Avatar
                      alt={solution.username || "Profile"}
                      src={solution.properties_public?.avatar_key ? `${IMAGE_CDN}/${solution.properties_public.avatar_key}` : undefined}
                      sx={{ width: 24, height: 24, marginRight: 0.5 }}
                    />
                    <span className="card-username">{solution.username}</span>
                  </Link>
                </Tooltip>
                <Tooltip title={formatDate(solution.created_ts)} arrow>
                  <span className="card-date">
                    {`• ${formatRelativeTime(solution.created_ts)}`}
                  </span>
                </Tooltip>
              </div>
                <div className="icon-more text-xl">
                  <button
                    className="card-more size-9 rounded-full flex justify-center items-center"
                    onClick={handleMenuToggle}
                    ref={moreIconRef}
                  >
                    <MoreHorizOutlinedIcon className="icon-stroke"/>
                  </button>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  ref={menuRef}
                >
                  {currentUser?.id === solution.user_id && (
                    <>
                      <MenuItem onClick={handleEdit}>
                        <EditOutlinedIcon fontSize="small" />
                      </MenuItem>
                      <MenuItem onClick={handleDelete}>
                        <DeleteOutlineOutlinedIcon fontSize="small" />
                      </MenuItem>
                    </>
                  )}
                  <Report
                      entityId={solution.id.toString()}
                      entityType="solution"
                      isOpen={isReportModalOpen}
                      setIsOpen={setIsReportModalOpen}
                      modalRef={reportModalRef}
                      onReportClick={handleReportClick}
                  />
                </Menu>
              </div>
            )}
            <div
              className="card-header text-xl font-semibold mt-1"
              onClick={handleCardClick}
            >
              <Link to={solutionHref} className="card-title">
                {solution.name}
              </Link>
              <div className="card-tags flex flex-row gap-2 my-1">
                <Chip
                component={'div' as any}
                label="Solution"
                color="neutral"
                variant="soft"
                size="md"
                sx={{
                    backgroundColor: '#005286',
                    color: '#fff',
                    fontSize: '0.75rem',
                }}
                >
                    Solution
                </Chip>
              </div>
            </div>
            <div className="flex flex-col-reverse md:flex-row gap-2">
              <div className="card-description md:w-[70%]" onClick={handleCardClick}>
                <span onClick={handleDescriptionClick}>
                  {renderDescription()}
                </span>
              </div>
              {solution.reference_link && (
                <EntityThumbnail 
                  url={solution.reference_link || ''}
                  urlType="page"
                  isProfilePage={isSolutionProfilePage}
                  onCardClick={handleCardClick}
                  referenceLink={solution.reference_link}
                />
              )}
            </div>
            {solution.reference_link && (
            <a
                className="external-link break-all block text-xs py-4 lg:pt-0"
                href={solution.reference_link}
                target="_blank"
                rel="noopener noreferrer"
            >
                {solution.reference_link}
            </a>
            )}
          </div>
        </div>
      </article>
      {/* Delete Confirmation Dialog */}
      <Modal
        open={isDeleteDialogOpen}
        onClose={cancelDelete}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <ModalDialog>
          <DialogTitle id="delete-dialog-title">{'Delete Solution'}</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this solution?
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelDelete} color="neutral">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="danger">
              Delete
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </div>
  );
};

export default SolutionCard;
