import React, { useState, useEffect, useRef } from 'react';
import { useUser } from 'util/UserContext';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import api from 'config/axiosConfig';
import Feed from '../Feed';
import UserProfileSaved from './UserProfileSaved';
import { User } from 'util/constants';
import { setDocumentTitle } from 'util/pageTitles';
import { capitalizeFirstLetter } from 'util/textUtils';
import ScrollButton from 'ui/ScrollButton';

const UserProfile: React.FC = () => {
  const { currentUser } = useUser();
  const { username, tab } = useParams<{ username: string; tab?: string }>();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<string>(tab || 'overview');
  const [usernameActive, setUsernameActive] = useState<User | null>(null);
  const [error, setError] = useState<string>('');

  // Refs and state for scroll buttons
  const navRef = useRef<HTMLDivElement>(null);
  const [showLeftScroll, setShowLeftScroll] = useState<boolean>(false);
  const [showRightScroll, setShowRightScroll] = useState<boolean>(false);

  // Function to check whether to show scroll buttons
  const checkForScrollButtons = () => {
    if (navRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = navRef.current;
      setShowLeftScroll(scrollLeft > 1); // Adjusted to account for rounding errors
      setShowRightScroll(Math.ceil(scrollLeft + clientWidth) < scrollWidth - 1);
    }
  };

  useEffect(() => {
    setDocumentTitle(`${username} - ${capitalizeFirstLetter(selectedTab)}`);
  }, [username, selectedTab]);

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const response = await api.get<User>(`/api/user/${username}`);
        setUsernameActive(response.data);
      } catch (error) {
        console.error(`Error fetching user with username: ${username}`, error);
        setError(
          `User with username ${username} does not exist or is inactive.`
        );
      }
    };

    fetchUsername();
  }, [username]);

  useEffect(() => {
    const handleResize = () => {
      checkForScrollButtons();
    };

    checkForScrollButtons(); // Initial check

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Check again after a short delay to account for any layout shifts
    const timer = setTimeout(() => {
      checkForScrollButtons();
    }, 100);

    return () => clearTimeout(timer);
  }, [selectedTab, currentUser?.username]); // Re-run when dependencies change

  const handleScroll = () => {
    checkForScrollButtons();
  };

  const scroll = (direction: 'left' | 'right') => {
    if (navRef.current) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      navRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleTabChange = (newTab: string) => {
    setSelectedTab(newTab);
    navigate(`/user/${username}/${newTab}`);
  };

  if (!usernameActive) {
    return (
      <div className="modal flex items-center justify-center">
        <div className="bg-eoe-bg dark:bg-eoe-dark-bg p-6 rounded-md">
          <p className="error-message">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="py-0 relative">
      {/* Tabs Navigation */}
      <div className="p-2 relative">
        <div className="relative flex items-center overflow-hidden">
          {/* Left Scroll Button */}
          <ScrollButton
            direction="left"
            onClick={() => scroll('left')}
            visible={showLeftScroll}
          />
          {/* Scrollable NavLinks */}
          <div
            ref={navRef}
            onScroll={handleScroll}
            className="flex space-x-4 overflow-x-auto whitespace-nowrap scrollbar-hide w-full"
          >
            <NavLink
              to={`/user/${username}`}
              end
              className={({ isActive }) =>
                isActive || (!tab && selectedTab === 'overview')
                  ? 'tab-active flex-shrink-0'
                  : 'tab-inactive flex-shrink-0'
              }
              onClick={() => handleTabChange('overview')}
            >
              Overview
            </NavLink>
            <NavLink
              to={`/user/${username}/posts`}
              className={({ isActive }) =>
                isActive || selectedTab === 'posts'
                  ? 'tab-active flex-shrink-0'
                  : 'tab-inactive flex-shrink-0'
              }
              onClick={() => handleTabChange('posts')}
            >
              Posts
            </NavLink>
            <NavLink
              to={`/user/${username}/comments`}
              className={({ isActive }) =>
                isActive || selectedTab === 'comments'
                  ? 'tab-active flex-shrink-0'
                  : 'tab-inactive flex-shrink-0'
              }
              onClick={() => handleTabChange('comments')}
            >
              Comments
            </NavLink>
            <NavLink
              to={`/user/${username}/feedback`}
              className={({ isActive }) =>
                isActive || selectedTab === 'feedback'
                  ? 'tab-active flex-shrink-0'
                  : 'tab-inactive flex-shrink-0'
              }
              onClick={() => handleTabChange('feedback')}
            >
              Feedback
            </NavLink>
            {username === currentUser?.username && (
              <NavLink
                to={`/user/${username}/saved`}
                className={({ isActive }) =>
                  isActive || selectedTab === 'saved'
                    ? 'tab-active flex-shrink-0'
                    : 'tab-inactive flex-shrink-0'
                }
                onClick={() => handleTabChange('saved')}
              >
                Saved
              </NavLink>
            )}
          </div>
          {/* Right Scroll Button */}
          <ScrollButton
            direction="right"
            onClick={() => scroll('right')}
            visible={showRightScroll}
          />
        </div>
      </div>

      {/* Tabs Content */}
      <div className="grid grid-rows-auto grid-flow-row gap-2">
        {selectedTab === 'overview' && (
          <Feed
            username={username}
            entityTypes={[
              'enemy',
              'impact',
              'solution',
              'comment',
              'feedback',
            ]}
            hideTabList
          />
        )}
        {selectedTab === 'posts' && (
          <Feed
            username={username}
            entityTypes={['enemy', 'impact', 'solution']}
            hideTabList
          />
        )}
        {selectedTab === 'comments' && (
          <Feed username={username} entityTypes={['comment']} hideTabList />
        )}
        {selectedTab === 'feedback' && (
          <Feed username={username} entityTypes={['feedback']} hideTabList />
        )}
        {username === currentUser?.username && selectedTab === 'saved' && (
          <UserProfileSaved />
        )}
      </div>
    </div>
  );
};

export default UserProfile;
