import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import api from 'config/axiosConfig';
import { User } from 'util/constants';

interface UserContextProps {
  currentUser: User | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>;
  awaitingUser: boolean;
  showAuthModal: boolean;
  setShowAuthModal: React.Dispatch<React.SetStateAction<boolean>>;
  showUsernameModal: boolean;
  setShowUsernameModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshUser: () => Promise<void>;
}

interface UserProviderProps {
  children: ReactNode;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider = ({ children }: UserProviderProps) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [awaitingUser, setAwaitingUser] = useState(true);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showUsernameModal, setShowUsernameModal] = useState(false);

  const fetchUser = async () => {
    try {
      const response = await api.get('/api/user');
      setCurrentUser(response.data);
    } catch (error) {
      setCurrentUser(null);
    } finally {
      setAwaitingUser(false);
    }
  };

  const refreshUser = async () => {
    setAwaitingUser(true);
    await fetchUser();
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (currentUser) {
      setAwaitingUser(false);
      setShowAuthModal(false);
    }
  }, [currentUser]);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        awaitingUser,
        showAuthModal,
        setShowAuthModal,
        showUsernameModal,
        setShowUsernameModal,
        refreshUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
